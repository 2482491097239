import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { setSessionCookie, unsetSessionCookie, SessionContext } from '../Cookies';

function Logout() {
  let navigate = useNavigate();
  const session = useContext(SessionContext);
  useEffect(() => {
    if(session === undefined)
    {
        navigate("/");
    }

    const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ session_id: session })
      };
    fetch('https://riverlandtech.au/api/v1/account/logout.php', requestOptions)
          .then(response => response.json())
          .then((data) => {
                  unsetSessionCookie();
                  window.location = "/";
    });
  }, []);
  return null;
}

export default Logout;
