import { Route, Switch, Routes, Navigate } from "react-router-dom";
import loadable from '@loadable/component';
import { TransitionGroup } from "react-transition-group";
import { CSSTransition } from "react-transition-group";
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";
import { getSessionCookie, SessionContext } from "./Cookies";
import { getCart, CartContext } from "./Cart";
import { useState, useEffect } from "react";
import Logout from "./pages/Logout";
const Index = loadable(() => import('./pages/Index'));
const Products = loadable(() => import('./pages/Products'));
const About = loadable(() => import('./pages/About'));
const Privacy = loadable(() => import('./pages/Privacy'));
const Returns = loadable(() => import('./pages/Returns'));
const Webdev = loadable(() => import('./pages/components/webdev'));
const WebHosting = loadable(() => import('./pages/components/webhosting'));
const PhoneSystem = loadable(() => import('./pages/components/phonesystem'));
const ManagedServices = loadable(() => import('./pages/components/managedservices'));
const Computers = loadable(() => import('./pages/components/computers'));
const Infrastructure = loadable(() => import('./pages/components/it-infrastructure'));
const BlogsComponent = loadable(() => import('./pages/components/BlogsComponent'));
const TeamsComponent = loadable(() => import('./pages/components/TeamsComponent'));
const Blog = loadable(() => import('./pages/Blog'));
const BlogLocal = loadable(() => import('./pages/components/blogs/Local'));
const BlogRansomware = loadable(() => import('./pages/components/blogs/Ransomware'));
const BlogEmailSorting = loadable(() => import('./pages/components/blogs/EmailAutomation'));
const BlogAntivirus = loadable(() => import('./pages/components/blogs/Antivirus'));
const BlogCleaning = loadable(() => import('./pages/components/blogs/Cleaning'));
const BlogCloud = loadable(() => import('./pages/components/blogs/Cloud'));
const BlogMSP = loadable(() => import('./pages/components/blogs/MSP'));
const BlogPasswords = loadable(() => import('./pages/components/blogs/Passwords'));
const BlogVPNs = loadable(() => import('./pages/components/blogs/VPN'));
const BlogBrowsers = loadable(() => import('./pages/components/blogs/Browsers'));
const BlogMicrosoft = loadable(() => import('./pages/components/blogs/Microsoft'));
const BlogBuyersGuide = loadable(() => import('./pages/components/blogs/BuyersGuide'));
const BlogChatGPT = loadable(() => import('./pages/components/blogs/ChatGPT'));
const BlogCloudReliance = loadable(() => import('./pages/components/blogs/DocumentManagement'));
const Blog3CX = loadable(() => import('./pages/components/blogs/3CXBreach'));
const BlogSafety = loadable(() => import('./pages/components/blogs/Safety'));
const BlogMFA = loadable(() => import('./pages/components/blogs/MFA'));
const BlogBlogging = loadable(() => import('./pages/components/blogs/Blogging'));
const TeamShaun = loadable(() => import('./pages/components/blogs/shaun'));
const Login = loadable(() => import('./pages/Login'));
const Signup = loadable(() => import('./pages/Signup'));
const ProductCategories = loadable(() => import('./pages/components/ProductCategories'));
const ProductList = loadable(() => import('./pages/components/ProductsList'));
const Product = loadable(() => import('./pages/Product'));
const Cart = loadable(() => import('./pages/Cart'));
const POSCheckout = loadable(() => import('./pages/POSCheckout'));



function App() {
    const location = useLocation();
    const [session, setSession] = useState(getSessionCookie());
    const [cart, setCart] = useState(getCart());
    useEffect(
        () => {
          setSession(getSessionCookie());
        },
        [session]
      );
      useEffect(
        () => {
          setCart(getCart());
        },
        []
      );
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Riverland Technology Services offers a wide range of IT services and products. We are your one-stop shop for all things IT. RTS offers the Riverland's most premium managed IT services and have world-class support!"
                />
            </Helmet>
            <SessionContext.Provider value={session}>
                <CartContext.Provider value={cart}>
                    <TransitionGroup component={null}>
                        <CSSTransition key={location.key} timeout={300} classNames="fade">
                            <Routes location={location}>
                                <Route exact path="/" element={<Index />}></Route>
                                <Route path="/products" element={<Products />}>
                                    <Route exact path="/products" element={<ProductCategories />}></Route>
                                    <Route exact path="/products/web-development" element={<Webdev />}></Route>
                                    <Route exact path="/products/web-hosting" element={<WebHosting />}></Route>
                                    <Route exact path="/products/phone-system" element={<PhoneSystem />}></Route>
                                    <Route exact path="/products/msp" element={<ManagedServices />}></Route>
                                    <Route exact path="/products/backups" element={<ManagedServices />}></Route>
                                    <Route exact path="/products/internet" element={<ManagedServices />}></Route>
                                    <Route exact path="/products/cloud" element={<ManagedServices />}></Route>
                                    <Route exact path="/products/it-infrastructure" element={<Infrastructure />}></Route>
                                    <Route exact path="/products/security" element={<ManagedServices />}></Route>
                                    <Route exact path="/products/computers" element={<Computers />}></Route>
                                    <Route exact path="/products/:category" element={<ProductList />}></Route>
                                </Route>
                                <Route path="/about" element={<About />}></Route>
                                <Route path="/privacy" element={<Privacy />}></Route>
                                <Route path="/returns" element={<Returns />}></Route>
                                <Route path="/blog" element={<Blog />}>
                                    <Route exact path="/blog" element={<BlogsComponent />}></Route>
                                    <Route exact path="/blog/why-you-should-choose-local" element={<BlogLocal />}></Route>
                                    <Route exact path="/blog/why-ransomware-is-a-threat" element={<BlogRansomware />}></Route>
                                    <Route exact path="/blog/why-you-need-a-password-manager" element={<BlogPasswords />}></Route>
                                    <Route exact path="/blog/what-antivirus-should-i-get" element={<BlogAntivirus />}></Route>
                                    <Route exact path="/blog/what-is-an-msp" element={<BlogMSP />}></Route>
                                    <Route exact path="/blog/vpns-what-are-they-do-i-need-one" element={<BlogVPNs />}></Route>
                                    <Route exact path="/blog/what-is-the-cloud-really" element={<BlogCloud />}></Route>
                                    <Route exact path="/blog/the-power-of-microsoft-365" element={<BlogMicrosoft />}></Route>
                                    <Route exact path="/blog/how-to-keep-yourself-cybersecure" element={<BlogSafety />}></Route>
                                    <Route exact path="/blog/the-msp-buyers-guide" element={<BlogBuyersGuide />}></Route>
                                    <Route exact path="/blog/how-to-keep-your-computer-clean" element={<BlogCleaning />}></Route>
                                    <Route exact path="/blog/3cx-breach" element={<Blog3CX />}></Route>
                                    <Route exact path="/blog/sms-mfa" element={<BlogMFA />}></Route>
                                    <Route exact path="/blog/the-importance-of-blogging" element={<BlogBlogging />}></Route>
                                    <Route exact path="/blog/how-can-i-rely-less-on-cloud" element={<BlogCloudReliance />}></Route>
                                    <Route exact path="/blog/which-browser-is-the-best" element={<BlogBrowsers />}></Route>
                                    <Route exact path="/blog/how-to-automate-email-sorting" element={<BlogEmailSorting />}></Route>
                                    <Route exact path="/blog/chatgpt-for-small-businesses" element={<BlogChatGPT />}></Route>
                                </Route>
                                <Route path="/team" element={<Blog />}>
                                    <Route exact path="/team" element={<TeamsComponent />}></Route>
                                    <Route exact path="/team/shaun" element={<TeamShaun />}></Route>
                                </Route>
                                <Route path="/login" element={<Login />}></Route>
                                <Route path="/signup" element={<Signup />}></Route>
                                <Route path="/logout" element={<Logout />}></Route>
                                <Route path="/cart" element={<Cart />}></Route>
                                <Route path="/pos" element={<POSCheckout />}></Route>
                                <Route path="/product" element={<Products />}>
                                    <Route exact path="/product" element={<Navigate to="/products" />}></Route>
                                    <Route exact path="/product/:productID" element={<Product />}></Route>
                                </Route>
                            </Routes>
                        </CSSTransition>
                    </TransitionGroup>
                </CartContext.Provider>
            </SessionContext.Provider>
            
        </>
    );
}

export default App;
