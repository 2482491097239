import { createContext } from "react";

export const setCart = (cart) => {
    localStorage.setItem('cart', JSON.stringify(cart));
};

function deepEqual(x, y) {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
      ok(x).length === ok(y).length &&
        ok(x).every(key => deepEqual(x[key], y[key]))
    ) : (x === y);
  }

export const addToCart = (productID, value) => {
    var cart = getCart();
    const product = cart.find((o, i) => {
        if(o.productID === productID && deepEqual(o.variations, value.variations))
        {
            cart[i].quantity += value.quantity;
            return true;
        }
    });
    if(!product)
    {
        cart.push({productID: productID, quantity: value.quantity, variations: value.variations});
    }
    setCart(cart);
};
export const removeFromCart = (productID, quantity) => {
    var cart = getCart();
    if(quantity > cart[productID])
    {
        cart[productID] = undefined;
    }
    else if(quantity === cart[productID])
    {
        cart[productID] = undefined;
    }
    else
    {
        cart[productID].quantity -= quantity;
    }
    setCart(cart);
};

export const getCart = () => {
    const cart = JSON.parse(localStorage.getItem('cart'));
    if(cart)
    {
        return cart;
    }
    else
    {
        return [];
    }
};

export const CartContext = createContext(getCart());