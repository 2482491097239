import * as Cookies from "js-cookie";
import { createContext } from "react";

export const setSessionCookie = (session) => {
  Cookies.remove("session");
  Cookies.set("session", session, { expires: 14 });
};

export const unsetSessionCookie = () => {
  Cookies.remove("session");
};

export const getSessionCookie = () => {
  const sessionCookie = Cookies.get("session");

  return sessionCookie;
};

export const SessionContext = createContext(getSessionCookie());